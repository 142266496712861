import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import axiosInstance from '../../utils/axiosInstance'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { MyKnownError } from '../types/common'
import { RootState } from '../store'
import { URL } from '../../constants/url'

export interface Note {
  id: number
  car: {
    id: number
    name: string
  }
  user: {
    id: number
    name: string
  }
  date: string
  description: string
  completed: boolean
  images: {
    lg: string
    md: string
    original: string
    sm: string
  }[]
}

export interface NotesState {
  currentNote: Note | null
  notes: Note[]
  isVisibleModalNote: boolean
  isPendingGetNotes: boolean
  // Загрузка
  isPendingAddNote: boolean
  isFulfilledAddNote: boolean
  isRejectAddNote: boolean
  isVisibleDeleteNote: boolean
}

const initialState: NotesState = {
  currentNote: null,
  notes: [],
  isVisibleModalNote: false,
  isPendingGetNotes: false,
  // Загрузка
  isPendingAddNote: false,
  isFulfilledAddNote: false,
  isRejectAddNote: false,
  isVisibleDeleteNote: false,
}

export const fetchNotes = createAsyncThunk(
  'notes/fetchNotes',
  async (carId: number, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.cars}/${carId}/notes`
      )
      if (response.statusText === 'OK') {
        return response.data
      }
      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError
      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при получении данных'
      )
    }
  }
)

type FetchAddNote = {
  id?: number | string
  description: string
  photos?: File[]
  completed?: boolean
  date?: string
}

export const fetchAddNote = createAsyncThunk(
  'notes/fetchAddNote',
  async (
    { description, completed = false, id, date, photos }: FetchAddNote,
    thunkAPI
  ) => {
    const {
      cars: { currentCar },
    } = thunkAPI.getState() as RootState
    const formData = new FormData()

    const formatDate = () => new Date().toISOString().split('T')[0]

    formData.append('date', date ? date : formatDate())
    formData.append('description', description)
    formData.append('completed', completed ? '1' : '0')

    if (photos && photos?.length > 0) {
      photos.forEach((photo, index) => {
        formData.append(`images[${index}]`, photo)
      })
    }

    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/api/cars/${currentCar?.id}/notes${id ? `/${id}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось создать заметку')
    } catch (error) {
      const typedError = error as MyKnownError
      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchDeleteNotes = createAsyncThunk(
  'notes/fetchDeleteNotes',
  async (_, thunkAPI) => {
    const {
      cars: { currentCar },
      notes: { currentNote },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.cars}/${currentCar?.id}/notes/${currentNote}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return { ...response.data, currentNote }
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const fetchDeleteNotesImage = createAsyncThunk(
  'notes/fetchDeleteNotesImage',
  async ({ imageId, noteId }: any, thunkAPI) => {
    const {
      cars: { currentCar },
    } = thunkAPI.getState() as RootState
    try {
      const response = await axiosInstance.delete(
        `/${URL.cars}/${currentCar?.id}/notes/${noteId}/image/${imageId}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return { ...response.data, noteId }
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setCurrentNote: (state, action) => {
      return { ...state, currentNote: action.payload }
    },
    setIsVisibleDeleteNote: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteNote: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotes.pending, (state) => {
      state.isPendingGetNotes = true
    })
    builder.addCase(fetchNotes.fulfilled, (state, action) => {
      state.notes = action.payload
      state.isPendingGetNotes = false
    })
    builder.addCase(fetchNotes.rejected, (state, action) => {
      toast.error(action.payload as string)
      state.isPendingGetNotes = false
    })
    builder.addCase(fetchAddNote.pending, (state) => {
      state.isVisibleModalNote = false
      state.isPendingAddNote = true
    })
    builder.addCase(fetchAddNote.fulfilled, (state, action) => {
      toast.success('Сохранено!')
      const isNoteExist = state.notes.find(
        (note) => note.id === action.payload.id
      )

      if (isNoteExist) {
        return {
          ...state,
          isFulfilledAddNote: true,
          isPendingAddNote: false,
          isRejectAddNote: false,
          notes: state.notes.map((note) =>
            note.id === action.payload.id ? action.payload : note
          ),
        }
      } else {
        return {
          ...state,
          isFulfilledAddNote: true,
          isPendingAddNote: false,
          isRejectAddNote: false,
          notes: [action.payload, ...current(state.notes)],
        }
      }
    })
    builder
      .addCase(fetchAddNote.rejected, (state, action) => {
        toast.error(action.payload as string)
        state.isVisibleModalNote = false
        state.isRejectAddNote = true
        state.isFulfilledAddNote = true
        state.isPendingAddNote = false
      })
      .addCase(fetchDeleteNotes.pending, (state) => {
        return { ...state, isPendingAddNote: true, isVisibleDeleteNote: false }
      })
      .addCase(fetchDeleteNotes.fulfilled, (state, action) => {
        toast.success('Удалено!')
        return {
          ...state,
          isVisibleDeleteNote: false,
          isPendingAddNote: false,
          notes: state.notes.filter(
            (item) => item.id !== action.payload.currentNote
          ),
        }
      })
      .addCase(fetchDeleteNotes.rejected, (state, action) => {
        toast.error(action.payload as string)

        return { ...state, isPendingAddNote: false, isVisibleDeleteNote: false }
      })
  },
})

export const { setCurrentNote, setIsVisibleDeleteNote } = notesSlice.actions

export default notesSlice.reducer
