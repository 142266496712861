/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames'
import styles from './ReportsFilter.module.css'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { carsSelector } from '../../../redux/selectors/cars-selector'
import { usersSelector } from '../../../redux/selectors/users-selector'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import {
  setSelectedDrivers,
  setSelectedTrucks,
  setSelectedStatus,
} from '../../../redux/reducers/report-filter'

interface Option {
  id: number
  name: string
}

interface DropdownProps {
  title: string
  isOpen: boolean
  setIsOpen: any
  selectedItems: any
  items: any[]
  handleChange: any
  handleSelectAll: any
  handleDeselectAll: any
  setSelectedDrivers?: any
  getReports?: any
}

const Dropdown = ({
  title,
  isOpen,
  setIsOpen,
  selectedItems,
  items,
  handleChange,
  handleSelectAll,
  handleDeselectAll,
  getReports,
}: DropdownProps) => {
  const ref = useOutsideClick(() => {
    setIsOpen(false)
  })

  const [itemsAfterUpdate, setItemsAfterUpdate] = useState<any[]>()

  useEffect(() => {
    //@ts-ignore
    const updatedItems = items.map((item) => {
      if (!item.active) {
        return { ...item, name: `${item.name} (disabled)` }
      }
      return item
    })
    setItemsAfterUpdate(updatedItems)
  }, [items])

  const selectAll = () => {
    handleSelectAll(itemsAfterUpdate)
  }

  const deselectAll = () => {
    handleDeselectAll()
  }

  return (
    <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <div className="d-lg-block mb-1">{title}</div>
      <div
        ref={ref}
        onClick={() => setIsOpen((prev: boolean) => !prev)}
        className={classNames(
          styles.dropdown,
          styles.dropdownMultipleSelect,
          'd-block',
          isOpen && styles.opened
        )}
        style={{ textAlign: 'left' }}
      >
        <div
          className={classNames(
            styles.btn,
            styles.btnSmall,
            styles.btnWide,
            styles.btnInput,
            styles.btnDropdown
          )}
        >
          {selectedItems.length
            ? selectedItems.map(({ name }: any) => name).join(', ')
            : `Все ${title}`}
        </div>
        <div
          className={styles.dropdownContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ textAlign: 'left' }}>
            <input
              className={styles.input}
              type="checkbox"
              name={`filter_${title.toLowerCase()}_all`}
              id={`${title.toLowerCase()}_all`}
              onChange={(e) => {
                if (e.target.checked) {
                  selectAll()
                } else {
                  deselectAll()
                }
              }}
            />
            <label
              className={styles.label}
              htmlFor={`${title.toLowerCase()}_all`}
            >
              <b>Все {title}</b>
            </label>
          </div>
          {itemsAfterUpdate?.map((item: any) => {
            return (
              <div key={item.id}>
                <input
                  className={styles.input}
                  type="checkbox"
                  name={`filter_${title.toLowerCase()}`}
                  id={`${item.id}`}
                  value={item.id}
                  checked={selectedItems.some(
                    (selected: any) => selected.id === item.id
                  )}
                  onChange={(e) => handleChange(e, item)}
                />
                <label className={styles.label} htmlFor={`${item.id}`}>
                  {item.name}
                </label>
              </div>
            )
          })}
          <div style={{ textAlign: 'left' }}>
            <div
              className={classNames(
                styles.btn,
                styles.btnSmall2,
                styles.btnBlueOutline,
                styles.btnDropdownClose
              )}
              onClick={() => {
                getReports()
                setIsOpen(false)
              }}
            >
              Применить
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const DropdownForUser = ({
  title,
  isOpen,
  setIsOpen,
  selectedItems,
  items,
  handleChange,
  handleSelectAll,
  handleDeselectAll,
  setSelectedDrivers,
  getReports,
}: DropdownProps) => {
  const dispatch = useDispatch()
  const ref = useOutsideClick(() => {
    setIsOpen(false)
  })

  const [itemsAfterUpdate, setItemsAfterUpdate] = useState<any[]>()
  const [openRoles, setOpenRoles] = useState({})
  const [selectedGroup, setSelectedGroup] = useState({})

  const toggleRole = (role: string) => {
    setOpenRoles((prevOpenRoles) => ({
      ...prevOpenRoles,
      //@ts-ignore
      [role]: !prevOpenRoles[role],
    }))
  }

  useEffect(() => {
    setItemsAfterUpdate(items)
  }, [items])

  const selectAll = () => {
    handleSelectAll(items)
  }

  const deselectAll = () => {
    handleDeselectAll()
  }

  const selectAllEmployeeByRole = (role: string) => {
    const filteredItems = items.filter((item: any) => item.role === role)
    handleSelectAll(filteredItems)
  }

  const deleteAllEmployeeByRole = (role: string) => {
    const roleItemIds = items
      .filter((item: any) => item.role === role)
      .map((item: any) => item.id)
    dispatch(
      setSelectedDrivers(
        selectedItems.filter((driver: any) => !roleItemIds.includes(driver.id))
      )
    )
  }

  useEffect(() => {
    //@ts-ignore
    const groupedItems = items.reduce((acc, item) => {
      if (!acc[item.role]) {
        acc[item.role] = []
      }
      acc[item.role].push(item)
      return acc
    }, {})

    const itemsByRole = Object.keys(groupedItems).map((role) => ({
      role,
      items: groupedItems[role],
    }))

    setItemsAfterUpdate(itemsByRole)
  }, [items])

  useEffect(() => {
    const driverCount = items.filter(
      (item: any) => item.role === 'driver'
    ).length

    const ownerCount = items.filter((item: any) => item.role === 'owner').length

    const selectedDriverCount = selectedItems.filter(
      (item: any) => item.role === 'driver'
    ).length

    const selectedOwnerCount = selectedItems.filter(
      (item: any) => item.role === 'owner'
    ).length

    if (
      selectedDriverCount === driverCount &&
      selectedOwnerCount === ownerCount
    ) {
      setSelectedGroup('all')
    } else if (selectedDriverCount === driverCount) {
      setSelectedGroup('driver')
    } else if (selectedOwnerCount === ownerCount) {
      setSelectedGroup('owner')
    } else {
      setSelectedGroup('')
    }
  }, [selectedItems, items])

  const getTitle = (role: string) => {
    switch (role) {
      case 'driver':
        return 'Водитель'
      case 'owner':
        return 'Владелец'
      default:
        break
    }
  }

  return (
    <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <div className="d-lg-block mb-1">{title}</div>
      <div
        ref={ref}
        onClick={() => setIsOpen((prev: boolean) => !prev)}
        className={classNames(
          styles.dropdown,
          styles.dropdownMultipleSelect,
          'd-block',
          isOpen && styles.opened
        )}
        style={{ textAlign: 'left' }}
      >
        <div
          className={classNames(
            styles.btn,
            styles.btnSmall,
            styles.btnWide,
            styles.btnInput,
            styles.btnDropdown
          )}
        >
          {selectedItems.length
            ? selectedItems.map(({ name }: any) => name).join(', ')
            : `Все ${title}`}
        </div>
        <div
          className={styles.dropdownContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.scrollDropdown}>
            <div
              style={{ textAlign: 'left' }}
              className={styles.dropdownInputRole}
            >
              <input
                className={styles.input}
                type="checkbox"
                checked={selectedGroup === 'all'}
                name={`filter_${title.toLowerCase()}_all`}
                id={`${title.toLowerCase()}_all`}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedGroup('all')
                    selectAll()
                  } else {
                    setSelectedGroup('')
                    deselectAll()
                  }
                }}
              />
              <label
                className={styles.label}
                htmlFor={`${title.toLowerCase()}_all`}
              >
                <b>Все {title}</b>
              </label>
            </div>

            {itemsAfterUpdate?.map((group) => (
              <div key={group.role}>
                <div className={styles.dropdownInputRole}>
                  <input
                    checked={
                      group.role === selectedGroup || selectedGroup === 'all'
                    }
                    id={`${group.role}`}
                    type="checkbox"
                    value={group.role}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedGroup(e.target.value)
                        selectAllEmployeeByRole(group.role)
                      } else {
                        if (selectedGroup === 'all') {
                          if (e.target.value === 'driver') {
                            setSelectedGroup('owner')
                          } else {
                            setSelectedGroup('driver')
                          }
                        } else {
                          setSelectedGroup('')
                        }
                        deleteAllEmployeeByRole(group.role)
                      }
                    }}
                  />
                  <label className={styles.label} htmlFor={`${group.role}`} />
                  <div
                    className={styles.roles}
                    onClick={() => toggleRole(group.role)}
                  >
                    <b>{getTitle(group.role)}</b>
                  </div>
                </div>

                {
                  //@ts-ignore
                  openRoles[group.role] && (
                    <div>
                      {group.items.map((item: any) => (
                        <div key={item.id} className={styles.dropdownInput}>
                          <input
                            className={styles.input}
                            type="checkbox"
                            name={`filter_${title.toLowerCase()}`}
                            id={`${item.id}`}
                            value={item.id}
                            checked={selectedItems.some(
                              (selected: any) => selected.id === item.id
                            )}
                            onChange={(e) => handleChange(e, item)}
                          />
                          <label
                            className={styles.label}
                            htmlFor={`${item.id}`}
                          >
                            {item.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  )
                }
              </div>
            ))}
          </div>
          <div style={{ textAlign: 'left' }}>
            <div
              className={classNames(
                styles.btn,
                styles.btnSmall2,
                styles.btnBlueOutline,
                styles.btnDropdownClose
              )}
              onClick={() => {
                getReports()
                setIsOpen(false)
              }}
            >
              Применить
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
interface DropdownStatusProps {
  title: string
  isOpen: boolean
  setIsOpen: any
  selectedItems: string[]
  items: string[]
  handleChange: any
  handleSelectAll: any
  handleDeselectAll: any
  getReports?: any
}

const DropdownStatus = ({
  title,
  isOpen,
  setIsOpen,
  selectedItems,
  items,
  handleChange,
  handleSelectAll,
  handleDeselectAll,
  getReports,
}: DropdownStatusProps) => {
  const ref = useOutsideClick(() => {
    setIsOpen(false)
  })

  const selectAll = () => {
    handleSelectAll(items)
  }

  const deselectAll = () => {
    handleDeselectAll()
  }

  const translations: { [key: string]: string } = {
    draft: 'Черновики',
    sent: 'Отправленные',
    closed: 'Закрытые',
  }

  return (
    <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <div className="d-lg-block mb-1">{title}</div>
      <div
        ref={ref}
        onClick={() => setIsOpen((prev: boolean) => !prev)}
        className={classNames(
          styles.dropdown,
          styles.dropdownMultipleSelect,
          'd-block',
          isOpen && styles.opened
        )}
        style={{ textAlign: 'left' }}
      >
        <div
          className={classNames(
            styles.btn,
            styles.btnSmall,
            styles.btnWide,
            styles.btnInput,
            styles.btnDropdown
          )}
        >
          {selectedItems.length
            ? selectedItems.map((item) => translations[item]).join(', ')
            : `Все ${title}`}
        </div>
        <div
          className={styles.dropdownContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ textAlign: 'left' }}>
            <input
              className={styles.input}
              type="checkbox"
              name={`filter_${title.toLowerCase()}_all`}
              id={`${title.toLowerCase()}_all`}
              checked={selectedItems.length === items.length}
              onChange={(e) => {
                if (e.target.checked) {
                  selectAll()
                } else {
                  deselectAll()
                }
              }}
            />
            <label
              className={styles.label}
              htmlFor={`${title.toLowerCase()}_all`}
            >
              <b>Все {title}</b>
            </label>
          </div>
          {items.map((item, index) => (
            <div key={index}>
              <input
                className={styles.input}
                type="checkbox"
                name={`filter_${title.toLowerCase()}`}
                id={`${item}`}
                value={item}
                checked={selectedItems.includes(item)}
                onChange={(e) => handleChange(e, item)}
              />
              <label className={styles.label} htmlFor={`${item}`}>
                {translations[item]}
              </label>
            </div>
          ))}
          <div style={{ textAlign: 'left' }}>
            <div
              className={classNames(
                styles.btn,
                styles.btnSmall2,
                styles.btnBlueOutline,
                styles.btnDropdownClose
              )}
              onClick={() => {
                getReports()
                setIsOpen(false)
              }}
            >
              Применить
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ReportsFilter({
  selectedTrucks,
  selectedDrivers,
  selectedStatus,
  getReports,
  isVisibleForm,
}: any) {
  const dispatch = useDispatch()
  const [isOpenTrucks, setIsOpenTrucks] = useState<boolean>(false)
  const [isOpenDrivers, setIsOpenDrivers] = useState<boolean>(false)
  const [isOpenStatus, setIsOpenStatus] = useState<boolean>(false)

  const { cars } = useSelector(carsSelector)
  const { users } = useSelector(usersSelector)

  const handleChangeCars = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: Option
  ) => {
    const { checked } = event.target

    dispatch(
      setSelectedTrucks(
        checked
          ? [...selectedTrucks, item]
          : selectedTrucks?.filter((option: any) => option.id !== item.id)
      )
    )
  }

  const handleChangeStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string
  ) => {
    const { checked } = event.target
    const updatedSelectedItems = checked
      ? [...selectedStatus, item]
      : selectedStatus.filter((selectedItem: any) => selectedItem !== item)

    dispatch(setSelectedStatus(updatedSelectedItems))
  }

  const handleSelectDriver = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: Option
  ) => {
    const { checked } = event.target
    dispatch(
      setSelectedDrivers(
        checked
          ? [...selectedDrivers, item]
          : selectedDrivers?.filter((driver: any) => driver.id !== item.id)
      )
    )
  }

  const handleSelectAllTrucks = (items: Option[]) => {
    dispatch(setSelectedTrucks(items))
  }

  const handleDeselectAllTrucks = () => {
    dispatch(setSelectedTrucks([]))
  }

  const handleSelectAllStatus = (items: Option[]) => {
    dispatch(setSelectedStatus(items))
  }

  const handleDeselectAllStatus = () => {
    dispatch(setSelectedStatus([]))
  }

  const handleSelectAllDrivers = (items: Option[]) => {
    dispatch(setSelectedDrivers(items))
  }

  const handleDeselectAllDrivers = () => {
    dispatch(setSelectedDrivers([]))
  }

  return (
    <form
      className={classNames(
        isVisibleForm ? styles.filterForm : '',
        styles.filterReports,
        'mb-3'
      )}
      id="filter_form"
    >
      <div
        className={classNames(
          styles.tableFilterPanel,
          styles.small,
          'row',
          'align-items-end'
        )}
      >
        <Dropdown
          title="Машины"
          isOpen={isOpenTrucks}
          setIsOpen={setIsOpenTrucks}
          selectedItems={selectedTrucks}
          items={cars}
          handleChange={handleChangeCars}
          handleSelectAll={handleSelectAllTrucks}
          handleDeselectAll={handleDeselectAllTrucks}
          getReports={getReports}
        />
        <DropdownForUser
          title="Водители"
          isOpen={isOpenDrivers}
          setIsOpen={setIsOpenDrivers}
          selectedItems={selectedDrivers}
          items={users.filter(
            (item) => item.role === 'driver' || item.role === 'owner'
          )}
          handleChange={handleSelectDriver}
          handleSelectAll={handleSelectAllDrivers}
          handleDeselectAll={handleDeselectAllDrivers}
          setSelectedDrivers={setSelectedDrivers}
          getReports={getReports}
        />
        <DropdownStatus
          title="Статусы"
          isOpen={isOpenStatus}
          setIsOpen={setIsOpenStatus}
          selectedItems={selectedStatus}
          items={['draft', 'sent', 'closed'] as string[]}
          handleChange={handleChangeStatus}
          handleSelectAll={handleSelectAllStatus}
          handleDeselectAll={handleDeselectAllStatus}
          getReports={getReports}
        />
      </div>
    </form>
  )
}

export default ReportsFilter
