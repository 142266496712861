import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from '@reduxjs/toolkit'
import { URL } from '../../constants/url'
import { AxiosError, AxiosResponse } from 'axios'
import axiosInstance from '../../utils/axiosInstance'
import { RootState } from '../store'
import { toast } from 'react-toastify'
import { getRole } from '../../utils/getRole'

export interface Expense {
  id?: number
  type: string
  payment_type: 'cash' | 'cash_check' | 'card' | 'card_check' | 'fuel_card'
  date: string
  quantity?: number
  amount?: number
  currency?: string
  description?: string
  images?: {
    original: string
    lg: string
    md: string
    sm: string
  }[]
}

// Начальное состояние

interface Image {
  original: string
  lg: string
  md: string
  sm: string
}

interface Vehicle {
  id: number
  name: string
}

export interface Report {
  id: number
  status: string
  user: User
  avg_cons: number
  fuel_before: number
  fuel_after: number
  departure: string
  mileage_before: number
  mileage_after: number
  mileage_full: number
  car: Vehicle
  trailer: Vehicle
  crossing_border_there: string
  crossing_border_back: string
  reimbursement_date: string
  expenses: Expense[]
  all_expenses: string | number
  arrival: string
  issued_byn: number
  issued_byn_balance: number
  issued_eur: number
  issued_eur_balance: number
  issued_rub: number
  issued_rub_balance: number
  report_pdf_url: string
  report_images_url: string
  number: string
}

export interface User {
  id: number
  name: string
  email: string
  avatar: {
    lg: string
    md: string
    original: string
    sm: string
  }
  active: boolean
  role: string
}

export interface Car {
  id: number
  name: string
  active: boolean
  image: {
    lg: string
    md: string
    original: string
    sm: string
  }
}

export interface Receipts {
  [key: string]: string[]
}

export interface InitState {
  isPendingGetReports: boolean
  isPendingGetCurrentReports: boolean

  isVisibleModalReport: boolean
  isVisibleDeleteModalReport: boolean
  currentReport: Report | null
  reports: Report[]
  // expenses
  currentExpenses: Expense | null
  expenses: Expense[] | []
  isLoading: boolean
  error: string | null
  isPendingAddExpense: boolean
  isFulfilledAddExpense: boolean
  isRejectAddExpense: boolean
  isPendingDeleteExpense: boolean
  isFulfilledDeleteExpense: boolean
  isRejectDeleteExpense: boolean
  isVisibleDeleteModalExpenses: boolean
  lastReportsQuery: any
  currentReportId: number | string | null
}

const initialState: InitState = {
  isPendingGetReports: false,
  isPendingGetCurrentReports: false,
  isVisibleModalReport: false,
  isVisibleDeleteModalReport: false,
  currentReport: null,
  reports: [],
  // expenses
  currentExpenses: null,
  expenses: [],
  isLoading: false,
  error: null,
  isPendingAddExpense: false,
  isFulfilledAddExpense: false,
  isRejectAddExpense: false,
  isPendingDeleteExpense: false,
  isFulfilledDeleteExpense: false,
  isRejectDeleteExpense: false,
  isVisibleDeleteModalExpenses: false,
  lastReportsQuery: [],
  currentReportId: null,
}

interface ReportsQuery {
  cars?: string[]
  employees?: string[]
  status?: string[]
  dateFrom?: Date | string
  dateTo?: Date | string
  orderBy?: string
  orderDirection?: string
}

export const fetchReports = createAsyncThunk(
  'reports/fetchReports',
  async (
    {
      cars,
      employees,
      dateFrom,
      dateTo,
      status,
      orderBy,
      orderDirection,
    }: ReportsQuery,
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams()

    const formatDate = (date: Date | string) => {
      const newDate = new Date(date)
      const formattedDate = newDate
        .toLocaleDateString('ru-RU', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          timeZone: 'Europe/Moscow',
        })
        .split('.')
        .reverse()
        .join('-')
      return formattedDate
    }

    //@ts-ignore
    const formattedDateFrom = formatDate(dateFrom)
    //@ts-ignore
    const formattedDateTo = formatDate(dateTo)

    if (cars?.length) params.append('car_ids', cars.join(','))
    if (employees?.length) params.append('user_ids', employees.join(','))
    if (status?.length) params.append('status', status.join(','))
    if (dateFrom) params.append('date_from', formattedDateFrom)
    if (dateTo) params.append('date_to', formattedDateTo)
    // orderBy
    //   ? params.append('order_by', orderBy)
    //   : params.append('order_by', 'date_from')
    params.append('order_direction', 'desc')

    try {
      const response = await axiosInstance.get(`/${URL.report}`, { params })

      if (response.statusText === 'OK') {
        return response.data
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error)
        return rejectWithValue(error.response?.data)
      } else {
        console.error('An unexpected error occurred:', error)
        return rejectWithValue('An unexpected error occurred')
      }
    }
  }
)

const sortByDate = (arr: Report[]) => {
  return Boolean(arr.length)
    ? arr.sort((a, b) => {
        return new Date(b.departure).getTime() - new Date(a.departure).getTime()
      })
    : arr
}

export const fetchCurrentReport = createAsyncThunk(
  'reports/fetchCurrentReport',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/${URL.report}/${id}`)

      if (response.statusText === 'OK') {
        return response.data
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error)
        return rejectWithValue(error.response?.data)
      } else {
        console.error('An unexpected error occurred:', error)
        return rejectWithValue('An unexpected error occurred')
      }
    }
  }
)

export const fetchAddReports = createAsyncThunk(
  'reports/fetchAddReports',
  async (
    {
      departure,
      mileageBefore,
      car,
      fuelBefore,
      fuelAfter,
      mileageAfter,
      trailer,
      crossingBorderThere,
      crossingBorderBack,
      avgCons,
      reimbursementDate,
      id,
      status,
      issuedByn,
      issuedEur,
      issuedRub,
      user,
      arrival,
      number,
    }: any,
    { rejectWithValue }
  ) => {
    const item = localStorage.getItem('userInfo')
    const userInfo = item ? JSON.parse(item) : {}

    const formData = new FormData()
    formData.append('departure', departure)
    formData.append('car_id', car)
    formData.append('mileage_before', mileageBefore)

    if (issuedByn) formData.append('issued_byn', issuedByn)
    if (issuedEur) formData.append('issued_eur', issuedEur)
    if (issuedRub) formData.append('issued_rub', issuedRub)

    if (arrival) formData.append('arrival', arrival)
    if (number) formData.append('number', number)

    if (status) formData.append('status', status)
    if (fuelBefore) formData.append('fuel_before', fuelBefore)
    formData.append('fuel_after', fuelAfter || 0)
    formData.append('mileage_after', mileageAfter || 0)
    formData.append('trailer_id', trailer)
    if (crossingBorderThere)
      formData.append('crossing_border_there', crossingBorderThere)
    if (crossingBorderBack)
      formData.append('crossing_border_back', crossingBorderBack)
    if (user) {
      formData.append('user_id', user)
    } else {
      formData.append('user_id', userInfo.id)
    }

    formData.append(
      'avg_cons',
      !isNaN(avgCons) && Boolean(avgCons) && isFinite(avgCons)
        ? avgCons
        : '0.01'
    )
    if (reimbursementDate)
      formData.append('reimbursement_date', reimbursementDate)

    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.report}${id ? `/${id}` : ''}`,
        formData
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error)
        return rejectWithValue(error.response?.data.message)
      } else {
        console.error('An unexpected error occurred:', error)
        return rejectWithValue('An unexpected error occurred')
      }
    }
  }
)

export const fetchReportFileByType = createAsyncThunk(
  'reports/fetchReportFile',
  async (
    { dateFrom, dateTo, exportFormat, getCars, getEmployees }: any,

    { rejectWithValue, getState }
  ) => {
    try {
      const params = new URLSearchParams()

      params.append('date_from', dateFrom)
      params.append('date_to', dateTo)
      params.append('export', exportFormat)

      getCars.forEach((car: string) => {
        params.append('cars[]', car)
      })

      getEmployees.forEach((employee: string) => {
        params.append('employees[]', employee)
      })
      let response
      const {
        reports: { currentReport },
      } = getState() as RootState

      response = await axiosInstance.get(`/${URL.report}/${exportFormat}`, {
        params,
        responseType: 'blob',
      })

      if (response.statusText === 'OK' || response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `report.${exportFormat}`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
        return response.data
      }

      throw new Error('Ошибка при получении файла')
    } catch (error) {
      console.error('Ошибка при получении файла:', error)
      return rejectWithValue('Ошибка при получении файла')
    }
  }
)

// export const fetchReportFile = createAsyncThunk(
//   'reports/fetchReportFile',
//   async (id: number, { rejectWithValue, getState }) => {
//     try {
//       const {
//         reports: { currentReportId },
//       } = getState() as RootState

//       if (!currentReportId) {
//         throw new Error('Отчет не найден')
//       }

//       const response = await axiosInstance.get(
//         `/${URL.report}/${id || currentReportId}/pdf`,
//         {
//           responseType: 'blob',
//           headers: {
//             Accept: 'application/pdf',
//           },
//         }
//       )

//       if (response.status === 200) {
//         const url = window.URL.createObjectURL(new Blob([response.data]))
//         const link = document.createElement('a')
//         link.href = url
//         link.setAttribute('download', 'report.pdf')
//         document.body.appendChild(link)
//         link.click()
//         link.remove()
//         window.URL.revokeObjectURL(url)
//         return response.data
//       } else {
//         throw new Error('Ошибка при получении файла')
//       }
//     } catch (error: any) {
//       console.error('Ошибка при получении файла:', error)
//       if (error?.response) {
//         console.error('Response data:', error.response.data)
//         console.error('Response status:', error.response.status)
//         console.error('Response headers:', error.response.headers)
//       } else if (error.request) {
//         console.error('Request data:', error.request)
//       } else {
//         console.error('Error message:', error.message)
//       }
//       return rejectWithValue('Ошибка при получении файла')
//     }
//   }
// )

export const fetchReportFile = createAsyncThunk(
  'reports/fetchReportFile',
  async (id: number, { rejectWithValue, getState }) => {
    try {
      const {
        reports: { currentReportId },
      } = getState() as RootState

      if (!currentReportId) {
        throw new Error('Отчет не найден')
      }
      const response = await axiosInstance.get(
        `/${URL.report}/${id || currentReportId}/pdf`,
        {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        }
      )

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        window.open(url, '_blank')
        return response.data
      } else {
        throw new Error('Ошибка при получении файла')
      }
    } catch (error: any) {
      console.error('Ошибка при получении файла:', error)
      if (error?.response) {
        console.error('Response data:', error.response.data)
        console.error('Response status:', error.response.status)
        console.error('Response headers:', error.response.headers)
      } else if (error.request) {
        console.error('Request data:', error.request)
      } else {
        console.error('Error message:', error.message)
      }
      return rejectWithValue('Ошибка при получении файла')
    }
  }
)

export const fetchDeleteReport = createAsyncThunk(
  'reports/fetchDeleteReport',
  async (_, thunkAPI) => {
    const {
      reports: { currentReportId },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.report}/${currentReportId}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

// expenses
export const fetchExpenses = createAsyncThunk(
  'expenses/fetchExpenses',
  async (reportId: number | string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.report}/${reportId}/expenses`
      )

      if (response.statusText === 'OK') {
        return response.data
      }
      throw new Error()
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при получении данных')
    }
  }
)

export const fetchAddExpense = createAsyncThunk(
  'expenses/fetchAddExpense',
  async (
    {
      type,
      payment_type,
      date,
      quantity,
      amount,
      currency,
      description,
      id,
      images,
    }: any,
    thunkAPI
  ) => {
    const {
      reports: { currentReportId, lastReportsQuery },
    } = thunkAPI.getState() as RootState

    const formData = new FormData()

    formData.append('type', type)
    formData.append('payment_type', payment_type)
    formData.append('date', date)

    formData.append('quantity', quantity || 0)
    if (amount) formData.append('amount', amount)
    if (currency) formData.append('currency', currency)
    formData.append('description', description || '')

    if (images?.length > 0) {
      //@ts-ignore
      images.forEach((photo, index) => {
        formData.append(`images[${index}]`, photo)
      })
    }

    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.report}/${currentReportId}/expenses${id ? `/${id}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        thunkAPI.dispatch(fetchReports(lastReportsQuery))
        if (currentReportId) {
          thunkAPI.dispatch(fetchCurrentReport(currentReportId))
        }

        return response.data
      }
      throw new Error('Не удалось создать запись')
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при добавлении данных')
    }
  }
)

export const fetchDeleteExpense = createAsyncThunk(
  'expenses/fetchDeleteExpense',
  async (_, thunkAPI) => {
    const {
      reports: { currentReportId, currentExpenses, lastReportsQuery },
    } = thunkAPI.getState() as RootState
    try {
      const response = await axiosInstance.delete(
        `/${URL.report}/${currentReportId}/expenses/${currentExpenses?.id}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        thunkAPI.dispatch(fetchReports(lastReportsQuery))
        if (currentReportId) {
          thunkAPI.dispatch(fetchCurrentReport(currentReportId))
        }

        return { ...response.data, id: currentExpenses?.id }
      }
      throw new Error('Не удалось удалить запись')
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при удалении данных')
    }
  }
)

export const fetchDeleteExpenseImage = createAsyncThunk(
  'expenses/fetchDeleteExpense',
  async ({ imageId, currentExpensesId }: any, thunkAPI) => {
    const {
      reports: { currentReportId, currentExpenses, lastReportsQuery },
    } = thunkAPI.getState() as RootState
    try {
      // eports/5/expenses/14/image/10

      const response = await axiosInstance.delete(
        `/${URL.report}/${currentReportId}/expenses/${currentExpensesId}/image/${imageId}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        thunkAPI.dispatch(fetchReports(lastReportsQuery))
        if (currentReportId) {
          thunkAPI.dispatch(fetchCurrentReport(currentReportId))
        }

        return { ...response.data, id: currentExpensesId }
      }
      throw new Error('Не удалось удалить запись')
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при удалении данных')
    }
  }
)

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setCurrentReport: (state, action) => {
      return { ...state, currentReport: action.payload }
    },
    setCurrentReportId: (state, action) => {
      return { ...state, currentReportId: action.payload }
    },
    setIsVisibleModalReport: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isVisibleModalReport: action.payload,
        currentReport: action.payload ? state.currentReport : null,
      }
    },
    setIsVisibleDeleteModalReport: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteModalReport: action.payload,
    }),
    // expenses
    setCurrentExpenses: (state, action) => {
      return { ...state, currentExpenses: action.payload }
    },
    clearExpenses: (state) => {
      return { ...state, expenses: [] }
    },
    setVisibleDeleteModalExpenses: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteModalExpenses: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReports.pending, (state, action) => {
        return { ...state, isPendingGetReports: true }
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        return {
          ...state,
          reports: action.payload,
          lastReportsQuery: action.meta.arg,
          isPendingGetReports: false,
        }
      })
      .addCase(fetchReports.rejected, (state, action) => {
        toast.error(action.payload as string)

        return { ...state, isPendingGetReports: false }
      })

      .addCase(fetchCurrentReport.pending, (state, action) => {
        return { ...state, isPendingGetCurrentReports: true }
      })
      .addCase(fetchCurrentReport.fulfilled, (state, action) => {
        return {
          ...state,
          currentReport: action.payload,
          isPendingGetCurrentReports: false,
        }
      })
      .addCase(fetchCurrentReport.rejected, (state, action) => {
        toast.error(action.payload as string)

        return { ...state, isPendingGetCurrentReports: false }
      })

      .addCase(fetchAddReports.pending, (state, action) => {
        return state
      })
      .addCase(fetchAddReports.fulfilled, (state, action) => {
        toast.success('Сохранено!')
        const isUserExist = current(state).reports.find(
          (item) => item.id === action.payload.id
        )

        if (isUserExist) {
          return {
            ...state,
            isVisibleModalReport: false,
            reports: state.reports.map((item) =>
              item.id === action.payload.id ? action.payload : item
            ),
          }
        }
        return {
          ...state,
          reports: sortByDate([action.payload, ...state.reports]),
          isVisibleModalReport: false,
        }
      })
      .addCase(fetchAddReports.rejected, (state, action) => {
        toast.error(action.payload as string)
        return {
          ...state,
          isVisibleModalReport: false,
        }
      })
      .addCase(fetchReportFile.pending, (state, action) => {
        return state
      })
      .addCase(fetchReportFile.fulfilled, (state, action) => {
        return state
      })
      .addCase(fetchReportFile.rejected, (state, action) => {
        toast.error(action.payload as string)

        return state
      })

    builder.addCase(fetchDeleteReport.pending, (state) => {
      return state
    })
    builder.addCase(fetchDeleteReport.fulfilled, (state, action) => {
      toast.success('Удалено!')

      return {
        ...state,
        isVisibleDeleteModalReport: false,
        currentReport: null,
        reports: state.reports.filter(
          (item) => item.id !== state?.currentReportId
        ),
      }
    })
    builder
      .addCase(fetchDeleteReport.rejected, (state, action) => {
        toast.error(action.payload as string)
        return { ...state, isVisibleDeleteModalReport: false }
      })

      // expensers
      .addCase(fetchExpenses.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(
        fetchExpenses.fulfilled,
        (state, action: PayloadAction<Expense[]>) => {
          state.expenses = action.payload
          state.isLoading = false
        }
      )
      .addCase(fetchExpenses.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload as string
      })
      .addCase(fetchAddExpense.pending, (state) => {
        state.isPendingAddExpense = true
      })
      .addCase(fetchAddExpense.fulfilled, (state, action) => {
        toast.success('Сохранено!')

        if (state.currentReport) {
          const isExist = state.currentReport.expenses?.find(
            (expense) => expense.id === action.payload.id
          )

          if (isExist) {
            state.currentReport.expenses = state.currentReport.expenses.map(
              (expense) =>
                expense.id === action.payload.id ? action.payload : expense
            )

            // state.reports = state.reports.map((report) =>
            //   //@ts-ignore
            //   report.id === state?.currentReport.id
            //     ? state.currentReport
            //     : report
            // )
          } else {
            // state.reports = state.reports.map((report) =>
            //   //@ts-ignore
            //   report.id === state?.currentReport.id
            //     ? state.currentReport
            //     : report
            // )

            state.currentReport.expenses = [
              action.payload,
              ...(state.currentReport.expenses || []),
            ]
          }
        }

        state.isPendingAddExpense = false
      })
      .addCase(fetchAddExpense.rejected, (state, action) => {
        state.isPendingAddExpense = false
        state.isRejectAddExpense = true
        state.error = action.payload as string
      })
      .addCase(fetchDeleteExpense.pending, (state) => {
        state.isPendingDeleteExpense = true
      })

      .addCase(
        fetchDeleteExpense.fulfilled,
        (state, action: PayloadAction<{ id: number }>) => {
          toast.success('Удалено!')

          if (state.currentReport) {
            state.currentReport.expenses = state.currentReport.expenses.filter(
              (expense) => expense.id !== action.payload.id
            )
            state.reports = state.reports.map((report) =>
              //@ts-ignore
              report.id === state?.currentReport.id
                ? state.currentReport
                : report
            )
          }
          state.isVisibleDeleteModalExpenses = false
          state.isPendingDeleteExpense = false
          state.isFulfilledDeleteExpense = true
        }
      )

      .addCase(fetchDeleteExpense.rejected, (state, action) => {
        toast.error(action.payload as string)

        state.isVisibleDeleteModalExpenses = false
        state.isPendingDeleteExpense = false
        state.isRejectDeleteExpense = true
        state.error = action.payload as string
      })
  },
})

export const {
  setCurrentReport,
  setIsVisibleModalReport,
  setIsVisibleDeleteModalReport,
  clearExpenses,
  setVisibleDeleteModalExpenses,
  setCurrentExpenses,
  setCurrentReportId,
} = reportsSlice.actions

export default reportsSlice.reducer
